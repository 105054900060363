.calendar-container {
      text-align: center;
  }
  
  .month-navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      margin-bottom: 28px;
  }
  
  .navigation-icon {
      cursor: pointer;
      font-size: 24px;
      color: #555;
      transition: color 0.3s ease;
  }
  
  .navigation-icon:hover {
      color: #000;
  }
  
  .navigation-icon.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
  }
  
  .month-range {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      font-weight: 400;
  }
  
  .header,
  .month {
      display: grid;
      grid-template-columns: repeat(7, 1fr); /* Ensures a consistent 7-column layout */
      gap: 5px; /* Optional spacing between cells */
      margin-bottom: 10px;
  }
  
  .header-day,
  .day {
      text-align: center;
      color: #5e5e5e;
      font-size: 16px;
  }
  
  .day-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .day_round {
      text-align: center;
      padding: 0;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      width: 40px;
      font-size: 16px;
      font-weight: 400;
  }
  
  .day.disabled {
      color: #ccc;
      pointer-events: none;
      cursor: not-allowed;
  }
  
  .selected {
      background-color: #4caf50;
      color: white;
      border-radius: 50%;
  }
  