.brach_name {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-style: 27px;
  color: black;
}

.addres_text {
  font-family: "Calibri", sans-serif;
  font-weight: 350;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-style: 20px;
  color: black;
}

.sidebar_List {
  font-family: "Calibri", sans-serif;
  font-weight: 550;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 20px;
}

.cart_product_name {
  font-family: "Calibri", sans-serif;
  font-weight: 590 !important;
  margin-bottom: 1.5px;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 22px;
}

@media (max-width: 760px) {
  .cart_product_name {
    font-family: "Calibri", sans-serif;
    font-weight: 600 !important;
    text-transform: none;
    letter-spacing: 0.25px;
    color: black;
    font-size: 16px;
  }
}

.cart_product_discription {
  font-family: "Calibri", sans-serif;
  font-weight: 550 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  line-height: 1.5;
  color: rgba(48, 50, 59, 0.73);
  font-size: 14px;
}

@media (max-width: 760px) {
  .cart_product_discription {
    font-family: "Calibri", sans-serif;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 0.25px;
    color: rgba(48, 50, 59, 0.73);
    font-size: 10px;
  }
}

.cart_product_chf {
  font-family: "Calibri", sans-serif;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 17px;
}

@media (max-width: 760px) {
  .cart_product_chf {
    font-family: "Calibri", sans-serif;
    font-weight: 600 !important;
    text-transform: none;
    letter-spacing: 0.25px;
    color: black;
    font-size: 15px;
  }
}

.removeCircleOutlineIcon {
  font-size: 30px !important;
}

.addCircleOutlineIcon {
  font-size: 30px !important;
}

.product_price {
  font-size: 22px !important;
}

@media (min-width: 992px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: -10px !important;
  }
}

@media (max-width: 760px) {
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: -6px !important;
  }
  .removeCircleOutlineIcon {
    font-size: 23px !important;
  }
  .product_price {
    font-size: 18px !important;
  }
  .addCircleOutlineIcon {
    font-size: 23px !important;
  }
}

.branch_name {
  font-family: "Calibri", sans-serif;
  font-weight: 400 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 28px;
}

@media (max-width: 760px) {
  .branch_name {
    font-family: "Calibri", sans-serif;
    font-weight: 400 !important;
    text-transform: none;
    letter-spacing: 0.25px;
    color: black;
    font-size: 20px;
  }
}

@media (max-width: 760px) {
  .Product_image {
    float: right;
    height: 100%;
    width: 100%;
  }
}

.branch_address {
  font-family: "Calibri", sans-serif;
  font-weight: 400 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: rgba(48, 50, 59, 0.73);
  font-size: 14px;
}

.order_summery {
  font-family: "Calibri", sans-serif;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 17px;
}

.add_more_items {
  font-family: "Calibri", sans-serif;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 17px;
}

.to_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 500 !important;
  font-style: bold;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 18px;
}

.sidemenu:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}
.sidemenu {
  transition: transform 0.5s;
}

.deails_fro {
  font-family: "Calibri", sans-serif;
  font-weight: 400 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
}

@media (max-width: 760px) {
  .deails_fro {
    font-family: "Calibri", sans-serif;
    font-weight: 400 !important;
    text-transform: none;
    letter-spacing: 0.25px;
    color: black;
    font-size: 14px;
  }
}
.hiaxaX123{

    left: 194px;
    height: 82%;
    width: auto;
    color: rgb(255, 233, 35);
    top: 105px;

}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

.pointer {
  cursor: pointer;
}


.form-control:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #076afd !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25) !important;
}