  .menu__{
                max-height: 250px !important ;
                  overflow-y: auto !important;
  }

      .dropdown-menu {
      background-color: #ffffff;
      font-family: "Calibri", sans-serif !important; /* Background color */
      border: 1px solid #ced4da; /* Border */
      border-radius: 0.25rem; /* Border radius */
      box-shadow: none !important; 
    }

    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.8em !important;
      margin-top: 1px !important;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      font-size: 20px !important;
  }



  @media (max-width: 767px) {
      .dropdown-menu[data-bs-popper] {
        top: 144% !important;
        left: -43px !important;
        margin-top: var(--bs-dropdown-spacer) !important;
      }
    }


    .dropdown-menu {
      --bs-dropdown-min-width: 115% !important;
      overflow-x: hidden !important;
  }