.header_success {
  font-family: "Calibri", sans-serif;
  font-weight: 600;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 23px;
}

.branch_name_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: black;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 28px;
}

.branch_add_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: #30323BBA;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 16px;
}

.icon_tic {
  font-size: 78px;
  color: #039855;
}

.name_customer {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 28px;
}

.title_hed {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 20px;
}

.cus_deteails {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  color: #344054;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 16px;
}

.pay_card {
  border-radius: 15px;
  border: 0px;
}






.order_num_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 20px;
}



/* Default styles (Desktop) */


@media only screen and (max-width: 768px) {
  .branch_name_pay {
    font-size: 20px;
  }

  


  .branch_add_pay {
    font-size: 12px;
  }

  .icon_tic {
    font-size: 50px;
  }

  .name_customer {
    font-size: 20px;
  }

  .title_hed {
    font-size: 15px;
  }

  .cus_deteails {
    font-size: 12px;
  }

  .pay_card {
    padding: 8px;
    border-radius: 10px;
  }

  .order_num_pay {
    font-size: 16px;
  }

  
}

.discont__pay {
  font-family: "Calibri", sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0.25px !important;
  color: green !important;
  font-size: 16px !important;
}

.discont__amount{
  font-family: "Calibri", sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0.25px !important;
  color: green !important;
  font-size: 18px !important; 
}





@media only screen and (max-width: 768px) {
  .header_success {
    font-size: 21px;
  }
}

.payment_text {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 19px;
  color: black;
}

.payment_order_id {
  font-family: "Calibri", sans-serif;
  font-weight: 550 !important;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 16px;
  color: black;
}

.payment_text_info {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  font-size: 14px;
  color: black;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

/* @media (max-width: 500px) { 
  .Download_Receipt {
    transition: transform 0.5s;
  }
}  */