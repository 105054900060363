/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Roboto:wght@300&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap'); */

@media screen and (min-width: 992px) {
  s .jIxqqQ {
    height: 440px;
  }
}

.card_common_ {
  border: 0 !important;
  border-radius: 0px;
}


.card_common_1 {
  border: 0 !important;
  border-radius: 8px;
}

.jIxqqQ {
  background-color: rgba(0, 0, 0, 0.15);
  height: 380px;
  width: 100%;
  position: relative;
}

::before,
::after {
  box-sizing: border-box;
}

.vhKwT {
  position: absolute;
  height: 100%;
  width: 100%;
}

.bBoPTF {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  height: 100%;
  position: relative;
}

.VyRky {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.cfQmWr {
  width: 100%;
  text-align: center;
}

.dJztCp {
  display: flex;
}

.iVhWQj {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}



@media screen and (min-width: 0) {
  .hsefJi {
    margin-top: 2rem;
  }
}

@media (min-width: 1200px) {
  .kZRcKV {
    margin-left: 25%;
  }
}

@media (min-width: 992px) {
  .kZRcKV {
    margin-left: 16.6667%;
  }
}

@media (min-width: 768px) {
  .kZRcKV {
    margin-left: 16.6667%;
  }
}

@media (min-width: 576px) {
  .kZRcKV {
    margin-left: 16.6667%;
  }
}

.kZRcKV {
  margin-left: 0%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (min-width: 576px) {
  .jXcHwn {
    width: 66.6667%;
  }
}

@media screen and (min-width: 0) {
  .jXcHwn {
    width: 100%;
  }
}

.bkSLXW {
  font-weight: 400;
  letter-spacing: normal;
  margin: 0.5rem 0px;
  color: rgb(0, 0, 0);
  background-color: rgb(141, 201, 34);
  font-family: "Kaushan Script", cursive;
  line-height: 1.2;
  font-size: 45px;
}



@media (max-width: 768px) {
  .bkSLXW {
    font-size: 30px;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 1415px) {
  .hsefJi {
    margin-top: -3rem;
  }
}

@media screen and (min-width: 1200px) {
  .hsefJi {
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) {
  .hsefJi {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .hsefJi {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  .jcVWLl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.jcVWLl {
  margin: 4rem auto 2rem;
  max-width: 1415px;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}

.iEcYKf {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.louJwp {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .kxgdSK {
    flex-wrap: nowrap;
    padding: 1rem;
  }
}

.kxgdSK {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 20px 0px;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1.5rem;
  width: 100%;
}

.bvywUR {
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -0.5rem;
}

@media (min-width: 992px) {
  .qOUAi {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .qOUAi {
    font-size: 18px;
  }
}

.qOUAi {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px auto 0.5rem;
  padding-right: 1.5rem;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 16px;
}

.jdkKSY {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}

@media (min-width: 992px) {
  .eSuUaj {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .eSuUaj {
    font-size: 18px;
  }
}

.eSuUaj {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  padding-top: 4px;
  color: rgb(32, 123, 187);
  font-weight: 700;
  border-color: rgb(32, 123, 187);
  border-bottom-style: dotted;
  border-width: 1px;
  font-size: 16px;
}

.kKzchl {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  color: rgb(127, 127, 127);
  font-size: 14px;
}

.button_main {
  display: flex !important;
}

.button_ {
  border-radius: 30px !important;
  padding: 8px 13px !important;
  display: flex !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  /* background-color: rgb(246, 246, 246) !important; */
  /* height: 32px !important; */
  box-shadow: rgb(6 121 105) 0px 0px 0px 2px inset !important;
  color: rgb(6, 121, 105) !important;
  border: none;
  background-color: rgb(241, 254, 252) !important
    /* margin-top: 9px !important; */
    /* margin-right: 23px !important; */
}

.button_simpal {
  border-radius: 30px;
  padding: 8px 13px;
  width: max-content;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(246, 246, 246);
  /* height: 32px; */
  color: rgb(26 23 23);
  border: none;
}

.Button_name {
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  /* letter-spacing: 1.01px; */
  line-height: 1.3336;
  margin: 0px;
  font-size: 16px;
}

@font-face {
  font-family: 'NandosHandAlt';
  font-style: normal;
  font-weight: 400;
  src: local('NandosHandAlt'), url('https://www.nandos.com.au/assets/font/nandos-hand-alt.woff2') format('woff2');
}

/* @font-face {
  font-family: 'Rajdhani';
  src: local('Rajdhani'), url(./fonts/Rajdhani/Rajdhani-Regular.ttf) format('truetype');
} */

.Popular_this_week {
  font-weight: 500;
  letter-spacing: normal;
  margin: 0.5rem 0px;
  transform: rotate(-3deg);
  font-family: 'Kaushan Script', cursive;
  line-height: 1.2;
  font-size: 50px;
}

.make_a_meal {
  font-weight: 500;
  letter-spacing: normal;
  margin: 0.5rem 0px;
  transform: rotate(-3deg);
  font-family: 'Kaushan Script', cursive;
  line-height: 1.2;
  font-size: 28px;
}

.make_a_meal_discription {
  letter-spacing: normal;
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  line-height: 1.2;
  font-size: 14px;
  color: #000000;
  text-align: center;
  padding: 20px;
}

.meal_discription {
  letter-spacing: 0.1px;
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  line-height: 1.2;
  font-size: 15px;
  color: #000000;
  text-align: start;
}

.meal_image {
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
  width: 80px;
}

.meal_title {
  letter-spacing: normal;
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  line-height: 26.6px;
  font-size: 20px;
  color: #000000;
  text-align: center;
  text-align: start;
}

.meal_name {
  letter-spacing: normal;
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 14px;
  color: #000000;
  text-align: center;
  padding: 13px;
  overflow: hidden;
  /* Ensures that the overflow content is not visible */
  text-overflow: ellipsis;
  /* Adds the ellipsis (...) when the text overflows */
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  max-width: 190px;
  /* Set a maximum width to trigger overflow (adjust as needed) */
  display: inline-block;
  /* Ensures the element behaves like an inline-block, allowing ellipsis */
  vertical-align: middle;
  /* Aligns the text vertically with images or other inline elements */
}



@media (max-width: 768px) {
  .Popular_this_week {
    font-size: 36px;
  }

  .make_a_meal {
    font-size: 28px;
  }

  .meal_image {
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
    width: 70px;
  }

  .Add-on-title_value {
    color: #000000;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 16.6px !important;
    text-align: end;
  }

  .meal_name {
    letter-spacing: normal;
    font-family: 'Calibri', sans-serif;
    font-weight: 600;
    line-height: 1.2;
    font-size: 13px;
    color: #000000;
    text-align: center;
    padding: 12px;
    overflow: hidden;
    /* Ensures that the overflow content is not visible */
    text-overflow: ellipsis;
    /* Adds the ellipsis (...) when the text overflows */
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    max-width: 100px;
    /* Set a maximum width to trigger overflow (adjust as needed) */
    display: inline-block;
    /* Ensures the element behaves like an inline-block, allowing ellipsis */
    vertical-align: middle;
    /* Aligns the text vertically with images or other inline elements */
  }
}

.cTKJEL {
  background-color: transparent;
  /* fill: rgb(244, 31, 139); */
}

.cecmhh {
  width: 75.667px;
}

.slider_title {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0px 0.5rem;
  font-weight: bold;
  font-size: 17px;
}

.slider_form {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0.5px 0px 0px;
  color: rgb(127, 127, 127);
  font-size: 14px;
}

.bHlsoG {
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0.5rem 0px 0px;
  font-size: 17px;
}

.bHlsoG_center {
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0.5rem 0px 0px;
  font-size: 15px;
}

.bHlsoG_discount {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0.5rem 0px 0px;
  font-size: 15px;
  text-decoration: line-through;
  color: #98A2B3 !important;
}

.bHlsoG_discount_centre {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0.5rem 0px 0px;
  font-size: 13px;
  text-decoration: line-through;
  color: #98A2B3 !important;
}

.kKzchl {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  color: rgb(127, 127, 127);
  font-size: 14px;
}

.apologies {
  font-family: 'Calibri', sans-serif;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
  text-align: start;
}

/* //////    Popular Meals///////////////// */

.jXIdmS {
  position: relative;
  /* clip-path: polygon(
    0px calc(0% + 5vw),
    100% 0px,
    100% calc(100% - 5vw),
    0% 100%
  ); */
  background-color: rgb(255, 160, 203);
  /* background-color: rgb(19, 18, 18); */
}

.scroll_section {
  min-height: 100vh;
}


.PriceAndButton {
  display: flex;
  justify-content: center;
  align-items: center;
}


.hOrWGh {
  position: relative;
  /* clip-path: polygon(
    0px calc(0% + 5vw),
    100% 0px,
    100% calc(100% - 5vw),
    0% 100%
  ); */
  background-color: rgb(138, 240, 225);
}

.kuBfuY {
  position: relative;
  /* clip-path: polygon(0px calc(0% + 5vw), 100% 0px, 100% calc(100% - 5vw), 0% 100%); */
  background-color: rgb(219, 246, 169);
}

.jbughb {
  position: relative;
  /* clip-path: polygon(
    0px calc(0% + 5vw),
    100% 0px,
    100% calc(100% - 5vw),
    0% 100%
  ); */
  background-color: rgb(255, 220, 167);
}

.jbughb1111 {
  position: relative;
  /* clip-path: polygon(
    0px calc(0% + 5vw),
    100% 0px,
    100% calc(100% - 5vw),
    0% 100%
  ); */
  /* background-color: rgb(20, 224, 194); */
  background-color: white;
}

.bcEVxw {
  height: 100%;
  width: 20%;
  position: absolute;
  top: 0px;
  right: 0px;
}

/* .hiaxaX {
  position: absolute;
  left: 161px;
  height: 82%;
  width: auto;
  color: rgb(255, 233, 35);
  top: 105px;
} */

.hiaxaX {
  position: absolute;
  right: -266px;
  height: 80%;
  width: auto;
  color: rgb(255, 233, 35);
  top: 105px;
}

.splide__arrow--prev {
  left: -2em !important;
  opacity: 1 !important;
}

.splide__arrow--next {
  right: -2em !important;
  opacity: 1 !important;
}

.discription_ {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  /* text-align: center; */
  font-size: 16px;
  color: rgb(26, 26, 26);
  margin-top: 10px;
}

.gXDrfW {
  transition: all 400ms ease-out 0s;
  transform: scale(1);
  opacity: 1;
  width: 100% !important;
  height: 100% !important;
}

.fMoaLC {
  max-width: 100%;
  vertical-align: top;
  object-fit: cover;
  height: 250px;
}

.container_gride_view {
  width: 100%;
  height: 265px;
  padding: 0 !important;
  border-radius: 8px 8px 0px 0px;
}

.container_gride_view .img-container {
  width: 100%;
  height: 265px !important;
}

.container_gride_view .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.container_mobile_view {
  width: 100%;
  height: 140px;
  padding: 0 !important;
  border-radius: 8px 0px 0px 8px;
  ;
}


.container_mobile_view .img-container {
  width: 100%;
  height: 140px !important;
}

.container_mobile_view .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/* .fMoaLC {
  width: 100% !important;
  vertical-align: top;
  object-fit: cover;
  height: auto !important;
    max-width: -webkit-fill-available;
    background-color: rgb(241, 246, 245);
} */

.slider__ {
  max-width: 100% !important;
  vertical-align: top;
  object-fit: cover;
  height: 250px !important;
}

.ffIvM {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 8px 8px 0px 0px;
}

.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  /* height: 225px !important; */
  /* background-size: cover !important; */
  /* background-repeat: repeat !important; */
  /* background-position: center center !important; */
}

.image-box img {
  max-width: 100%;
  transition: all 0.5s;
  display: block;
}

.image-box:hover img {
  transform: scale(1.1);
}

.pointer {
  cursor: pointer;
}

.Model_header_name {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.No_any_item {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 0.3336;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.btn-close {
  margin-bottom: -8px !important;
  text-transform: lowercase;
}

.Producet_discriptipon_ {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  white-space: pre-wrap;
  font-size: 16px;
  margin-top: 3px;
  /* color: rgb(255, 255, 255); */
  background-color: #f7f7f7;
}

.slider_title_listview {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  font-weight: 600;
  margin: 5px 5px 0px -9px;
  font-size: 14px;
}

.slider_title_listview_discount {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0.5rem 0px 0px;
  font-size: 12px;
  text-decoration: line-through;
  color: #98A2B3 !important;
}



.slider_form_listview {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0.5px -18px 0px;
  color: rgb(127, 127, 127);
  font-size: 14px;
}

/* .catagory_name {
  font-family: "Merienda, cursive";
} */

section {
  height: 100vh;
  width: 100%;
  background-color: #7e8081;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

/* .cont {
  overflow: hidden;
} */
/* li.active {
  border: 1px solid blue;
} */

.Add_cutome_css {
  color: #666;
  /* font-family: "Open Sans", HelveticaNeue-Light, "Helvetica Neue Light",
    "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  line-height: 1.75em;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: #000;
}

.add_on_name {
  cursor: pointer;
  line-height: 30px;
  /* border-bottom: 1px solid #e7e7e7; */
  color: #201c1d;
  margin-bottom: 0;
}

.modal-content {
  width: 100% !important;
  margin: 0 auto !important;
  border-radius: 0%;
}

.drawer_titel {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.3336;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
  font-size: 30px;
  margin-left: 135px;
}

@media (max-width: 768px) {
  .drawer_titel {
    font-size: 26px;
    margin-left: 85px;
  }
}


.drawer_titel_for_dine {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.3336;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
  font-size: 30px;
  margin-left: 128px;
  /* margin-left: 135px; */
}

@media (max-width: 768px) {
  .drawer_titel_for_dine {
    font-size: 26px;
    margin-left: 75px;
  }
}


.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y)) !important;
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.product_price12 {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0px 1rem;
  font-size: 16px !important;
}

.product_Name {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 17px;
  margin-top: 4px;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-overflow: ellipsis;
}

.Check_error_compare {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 15px;
  margin-top: 4px;
  font-weight: 400;
  color: #f30c0c;
  text-overflow: ellipsis;
}

.product_Name_Chf {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 15px;
  margin-top: 4px;
  font-weight: 500;
  /* text-transform: uppercase; */
  text-overflow: ellipsis;
}


.qut_price_chf {
  font-family: 'Calibri', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: black;
  font-size: 15px;
}


.dQXrff {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0.5px 0px 0px;
  color: rgb(127, 127, 127);
  font-size: 15px;
}

.Items_Added {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 20px;
  color: #686665;
  font-weight: 600 !important;
  padding: 5px;
}

.Items_Added_Canceeel {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 20px;
  color: #686665;
  font-weight: 600 !important;
}

.cancelled {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 20px;
  color: #140701;
  font-weight: 600 !important;
}

.Empty_cart {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 20px;
  color: #686665;
  font-weight: 600 !important;
}

@media (max-width: 768px) {
  .Items_Added {
    font-size: 17px;
  }
}



.price_amout {
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0px 1rem;
  font-size: 18px !important;
  color: rgb(1, 1, 1);
}

.Select_date_time {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.6px;
  line-height: 1.3336;
  margin: 7px 0px;
  font-weight: 500;
  color: #5a5a5a;
  font-size: 17px;
}

.Prepraed_Time {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.6px;
  line-height: 1.3336;
  color: #88888d;
  font-size: 0.85rem;
  font-weight: 400;
}

.Prepraed_Time_ {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.6px;
  line-height: 1.3336;
  color: rgb(23, 21, 21);
  font-size: 17px;
}

.button_name1 {
  font-family: 'Calibri', sans-serif !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  line-height: 1.3336 !important;
  font-size: 17px !important;
  text-align: center !important;
  padding: 12px 15px !important;
  font-size: 0.875rem;
  color: #88888d !important;
  text-transform: capitalize !important;
  /* border: 1px solid #ededed !important; */
}

.button_name1_delivery {
  font-family: 'Calibri', sans-serif !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  line-height: 1.3336 !important;
  font-size: 17px !important;
  text-align: center !important;
  padding: 12px 15px !important;
  font-size: 0.875rem;
  border: 1px solid rgb(7, 89, 240) !important;
  text-transform: capitalize !important;
  color: white !important;
  /* background-color: rgb(202, 13, 113); */
  /* border: 1px solid #ededed !important; */
}

.button_name1_Pickup {
  font-family: 'Calibri', sans-serif !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  line-height: 1.3336 !important;
  font-size: 17px !important;
  text-align: center !important;
  padding: 12px 15px !important;
  font-size: 0.875rem;
  border: 1px solid rgb(7, 89, 240) !important;
  text-transform: capitalize !important;
  color: white !important;
  /* background-color: rgb(202, 13, 113) ; */
  /* border: 1px solid #ededed !important; */
}

.Perfection_Method {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.6px;
  font-weight: 600;
  line-height: 1.3336;
  font-size: 19px;
  color: #5a5a5a;
}

.css-1uz84ag-MuiBadge-root .MuiBadge-badge {
  right: 3px !important;
  top: 13px !important;
  border: 2px solid #fff;
  padding: 9px 7px;
}

.TpJKR {
  position: absolute;
  height: 50%;
  max-width: 50%;
  top: 25%;
  left: 0%;
  fill: rgb(244, 31, 139);
  /* rgb(255, 151, 30); */
}

.catagory_disclaimer {
  font-family: 'Calibri', sans-serif;
  font-weight: 300;
  font-style: italic;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 14px;
  color: black;
  display: flex;
}

.no_product_available {
  font-family: 'Calibri', sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: center;
  font-size: 22px;
  color: black;
  margin-top: 10px;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

.custom_hover {
  border: 0px !important;
  border-radius: 10px !important;
  font-family: 'Calibri', sans-serif;
}

.cwDcuM {
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 40px;
  background-color: rgb(202, 13, 113);
  color: rgb(255, 255, 255);
  border: #fff 0px;
}

.bdegrl {
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
}

.dJztCp {
  display: flex;
}

.bKQrMI {
  padding: 0.24rem 0.5rem;
}

.cQvspK {
  width: 24px;
  height: 24px;
  margin-right: 0.25rem;
  fill: rgb(255, 255, 255);
}

.hover14 figure {
  position: relative;
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.dxHGJE {
  position: relative;
  z-index: 1;
  /* padding-bottom: 33px; */
}



@media screen and (min-width: 992px) {
  .bjjsup {
    padding-bottom: 1rem;
  }
}

.bjjsup {
  background-color: rgb(0, 0, 0);
  position: relative;
  z-index: 1;
  /* padding: 2rem 1.5rem; */
  padding: 24px 5px 1px 5px
}

.bhHZiE {
  position: absolute;
  top: -1px;
  left: 0px;
}

.dJztCp {
  display: flex;
}

.cbBucT {
  -webkit-box-pack: center;
  justify-content: center;
}


.hSRyOb {
  font-weight: 400;
  letter-spacing: normal;
  margin: 0px 0px 1rem;
  transform: rotate(-3deg);
  padding-bottom: 0px;
  color: rgb(255, 255, 255);
  text-align: center;
  /* font-family: 'Calibri', sans-serif; */
  font-family: "Kaushan Script", cursive;
  line-height: 1.2;
  font-size: 26px;
}


@media (max-width: 768px) {
  .hSRyOb {
    font-size: 20px;
  }

  .cZXofG {
    font-size: 12px !important;
  }
}

.chip_ {
  font-weight: 400;
  letter-spacing: 0.03px;
  color: #B42318 !important;
  text-align: center !important;
  font-family: 'Calibri', sans-serif !important;
  line-height: 1.2 !important;
  font-size: 13px !important;
  background: #FEF3F2 !important;
  height: 27px !important;
  margin-left: 8px !important;
}

.options_ {
  font-weight: 400;
  letter-spacing: 0.03px;
  color: #686665 !important;
  font-family: 'Calibri', sans-serif !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
}

.eVQLUW {
  background-color: rgb(202, 13, 113);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 1.4;
  -webkit-box-decoration-break: clone;
}


.dHERSC {
  padding: 1.5rem 2rem;
  border-radius: 4px;
  border-style: solid;
  background-color: rgb(233, 233, 233);
  border-color: rgb(233, 233, 233);
  color: rgb(185, 185, 185);
  fill: rgb(185, 185, 185);
  cursor: auto;
  font-size: 12px;
  font-weight: 700;
  height: 44px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out 0.3s;
  font-family: Barlow, Calibri, sans-serif;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}

.cZXofG {
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 5px;
  color: rgb(255, 255, 255);
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;
}

.kNLGCO {
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  top: 1rem;
  left: 1rem;
  background-color: rgba(255, 255, 255, 0.822);
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
}

.dqObtv {
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  color: rgb(76, 76, 76);
  font-weight: bold;
  font-size: 16px;
  font-family: 'Calibri', sans-serif !important;
}

.cZXofG__ {
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  color: rgb(66, 63, 63);
  text-align: start;

}


.cZXofG__11_ {
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  color: rgb(66, 63, 63);
  text-align: start;
  font-size: 12px;
  margin: 7px 3px 13px -9px;
  height: 53px;
}

.cZXofG__11_blank {
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  color: rgb(66, 63, 63);
  text-align: start;
  font-size: 12px;
  margin: 0px;
  height: 53px;
}

.text-overflow {
  /* white-space: nowrap;           */
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  height: 64px;
}

.dWXQvF {
  width: 100%;
  height: 66px;
  position: absolute;
  margin-top: -33px;
}

.ccQxDo {
  width: 100%;
}

.iEcYKf {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.dJztCp {
  display: flex;
}

.VyRky {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.hvIuqM {
  width: 100%;
}

.jqilse {
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  text-align: center;
  font-size: 14px;
}

.jKvBNZ {
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px 0px 0.5rem;
  font-weight: bold !important;
  font-size: 18px;
}

.hMTMzH {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product_Name_table {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 19px;
  margin-top: 4px;
  font-weight: bold;
  /* text-transform: uppercase; */
  text-overflow: ellipsis;
  margin: 7px
}

.tabel_chf {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 16px;
  margin-top: 4px;
  font-weight: 500;
  margin-top: -6px;
  margin-left: 8px
}

.tabel_chf_count {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 16px;
  margin-top: -6px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .tabel_chf_count {
    margin-top: -6px;
    font-size: 15px;

  }
}



.check_out_div {
  margin: 15px 5px 9px 0px
}

@media (max-width: 768px) {
  .Check_ {
    height: 24px;
  }
}

@media (max-width: 768px) {
  .check_out_div {
    margin: 13px 0px 2px -3px
  }
}





.Items_Your {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  line-height: 1.3336;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 9px;
  font-weight: 600 !important;
  /* text-align: start; */
  padding: 5px
}

.dineiN_contain {
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  border: 0;
  text-transform: none;
  height: 36px;
  color: white;
  font-size: 15px;
  /* width: 205px; */
  border-radius: 17px !important;
  background-color: #dc3545;
  max-width: fit-content;
  margin-right: 9px;
}

.cont::-webkit-scrollbar {
  display: none !important;
}

.cont {
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* width: 0;
  height: 0; */

}

.dine_nin_no {
  font-size: 17px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: 1.01px;
  margin-left: -10px;
  font-weight: 600;
}

.Table_no_ {
  font-size: 17px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: 1.01px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .Table_no_ {
    font-size: 15px;
  }
}



.container_Pop_po {
  width: 100%;
  /* height: 330px;  */
  height: 230px;
  padding: 0 !important;
}

.container_Pop_po .img-container {
  width: 100%;
  /* height: 330px !important; */
  height: 230px !important;

}

.container_Pop_po .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media screen and (min-width: 0) {
  .buTYIR {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.buTYIR {
  padding: 1.5rem 1rem;
  border: none;
  border-radius: 0px;
  background-color: rgb(202, 13, 113);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 700;
  height: 56px;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.2s ease-in-out 0.3s;
  font-family: 'Calibri', sans-serif;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.kbtvSC {
  display: flex;
  width: 100%;
}

.eoyNDU {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.dGLkIz {
  display: flex;
  height: 20px;
  width: 20px;
  overflow: hidden;
}

.kOJnvv {
  border: 1px solid;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 20px;
  width: 20px;
  overflow: hidden;
}

.gBiwdM {
  font-size: 12px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  display: block;
  font-weight: bold;
  position: absolute;
  text-transform: uppercase;
}

.jYBevi {
  font-size: 12px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
}

.dJztCp {
  display: flex;
}

.jrRXku {
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.enpebl {
  font-size: 12px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
}


/* //////       tablet //////////// */

@media (max-width: 992px) {
  .gQEuSb {
    position: fixed;
    bottom: 0px;
    z-index: 100;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: rgba(255, 255, 255, 0.9);
    animation: 1s ease-in 0s 1 normal none running background-animation;
  }
}

.gQEuSb {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .buTYIR {
    font-size: 16px;
    height: 68px;
  }
}

@media screen and (min-width: 576px) {
  .buTYIR {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 0) {
  .buTYIR {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  .buTYIR {
    font-size: 13px;
  }
}

@media screen and (min-width: 576px) {
  .buTYIR {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.buTYIR {
  padding: 1.5rem 1rem;
  border: none;
  border-radius: 0px;
  background-color: rgb(202, 13, 113);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 700;
  height: 56px;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.2s ease-in-out 0.3s;
  font-family: 'Calibri', sans-serif;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.viewport-height {
  height: 100vh;
}

.dine_in_secc {
  font-size: 22px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: 1.01px;
  line-height: 1.3336;
  font-weight: bold;
}

.dine_in_Qr {
  font-size: 15px;
  font-family: 'Calibri', sans-serif;
  letter-spacing: 1.01px;
  line-height: 1.3336;
  /* font-weight: bold; */
  color: '#5b5c5d';
}

.Span_image {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}

.default_background {
  width: 100%;
  height: 100%;
  background-color: rgb(199 199 195 / 8%);
  border-bottom: 1px solid rgb(233, 233, 233);
  border-top: 1px solid rgb(233, 233, 233);
}


.venue-header-inner {
  width: 100px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 10px;
}

.text-overflow-fetcher {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  height: 63px;
  font-size: 15px;
}

.Add-on-title {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  line-height: 26.6px;
}


.Add-on-title_key {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 400;
  font-size: 15px;
  line-height: 27.6px;
}

.Add-on-title_value {
  font-family: 'Calibri', sans-serif;
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  font-size: 15px;
  line-height: 27.6px;
  text-align: end;
}


.venue-name {
  font-size: 2rem;
  color: #fff;
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  line-height: 1.3336;
  margin: 0px;
}

.venue-contact {
  font-size: 0.9rem;
  color: #fff;
}

.venue-more {
  font-size: 0.9rem;
  color: #fff;
  cursor: pointer;
  margin-top: -10px;
}

.venue-header-inner .btn-light {
  padding: 0.1rem 0.5rem;
}


.hpdzON {
  position: sticky;
  top: 0px;
  right: 0px;
  z-index: 2;
  /* margin-top: -32px; */
  /* padding-top: 32px; */
}


.knnEfP {
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  /* background-color: rgba(255, 255, 255, 0.4); */
  background-color: rgba(255, 255, 255, 0.822);
  border-radius: 50%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  border: none;
}


.kUPGkL {
  height: 24px;
  width: 24px;
  fill: rgb(0, 0, 0);
}


.modal-product-details .btn-close {
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 18px;
  width: 28px !important;
  height: 28px !important;
  margin: 0;
  z-index: 100000;
  background-color: #ffffff;
  color: #000;
  text-decoration: none;
  opacity: .75;
}


.custom-modal-body {
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Custom scrollbar styles for the modal body */
.custom-modal-body::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

.custom-modal-body::-webkit-scrollbar-track {
  background: #fffefe;
  /* Color of the track */
}

.custom-modal-body::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  /* Color of the scrollbar thumb */
  border-radius: 0px;
  /* Round the scrollbar thumb */
  min-height: 10px;
  /* Minimum height of the scrollbar thumb */
}

.custom-modal-body::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
  /* Color when hovering over the scrollbar thumb */
}

:root {
  --fuschia: #ff0081;
  --button-bg: var(--fuschia);
  --button-text-color: #fff;
  --baby-blue: #f8faff;
}

body {
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  /* text-align: center; */
  background-color: var(--baby-blue);
}

.bubbly-button {
  text-align: center;
  font-family: 'Calibri', sans-serif;
  font-size: 17px;
  -webkit-appearance: none;
  appearance: none;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  height: 40px;
  z-index: 1111;
  width: 150px;
}

.bubbly-button:focus {
  outline: 0;
}

.bubbly-button:before,
.bubbly-button:after {
  position: absolute;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--button-bg) 20%, transparent 30%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--button-bg) 15%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--button-bg) 15%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%),
    radial-gradient(circle, var(--button-bg) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.bubbly-button:active {
  transform: scale(0.9);
  background-color: #e60073;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.bubbly-button.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}

.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}