@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Roboto:wght@300&display=swap");
.GridView_hover:hover {
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
}
.GridView_hover {
  transition: transform 0.5s;
}

.branch_name {
  font-family: "Baloo 2", cursive;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 25px;
  color: black;
}

.address {
  font-family: "Baloo 2", cursive;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 14px;
  color: black;
}

#product_catagory {
  font-family: "Baloo 2", cursive;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 17px;
  color: black;
}

.catagory_description {
  font-family: "Baloo 2", cursive;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 14px;
  color: black;
  display: flex;
}

.catagory_disclaimer {
  font-family: "Baloo 2", cursive;
  font-weight: 300;
  font-style: italic;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 14px;
  color: black;
  display: flex;
}

.product_name {
  font-family: "Baloo 2", cursive;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 17px;
}

.product_description {
  font-family: "Baloo 2", cursive;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: rgba(48, 50, 59, 0.73);
  line-height: 1.5;
  font-size: 12px;
  overflow: hidden;
}

.modal-content {
  width: 96%;
  margin: 0 auto !important;
}

.menu-product-image {
  width: 100%;
  height: 270px;
  margin-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  vertical-align: middle;
  border-style: none;
}

.product_chf {
  margin: 0px;
  font-family: "Baloo 2", cursive;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: black;
  font-style: normal;
  font-size: 18px;
}

.checbox_tic {
  font-family: "Baloo 2", cursive;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: rgba(48, 50, 59, 0.73);
}

.qut_price {
  font-family: "Baloo 2", cursive;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.25px;
  color: black;
  font-size: "16px";
}

.Listview_hover:hover {
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
}
.Listview_hover {
  transition: transform 0.5s;
}

@media (max-width: 500px) {
  .GridView_hover:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  .GridView_hover {
    transition: transform 0.5s;
  }
}

.add_itm_to_continues_button {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  border: 0;
  text-transform: none;
  height: 36px;
  color: white;
  font-size: 15px;
  width: 205px;
  border-radius: 17px !important;
  background-color: #dc3545;
}

.Total_item {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  letter-spacing: 4.25px;
  border: 0;
  text-transform: none;
  font-size: 22px;
}

.view_cart_icon {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  letter-spacing: 0.25px;
  border: 0;
  text-transform: none;
  font-size: 22px;
}

@media (max-width: 360px) {
  .sticky_tap {
    width: 360px;
  }
}

/* .mobile_view_cart {
  display: none;
} */

.modal-body {
  overflow-x: hidden !important;
}

@media (max-width: 760px) {
  .mobile_view_cart {
    display: contents;
  }
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* @media (max-width: 760px) {
  .product_view_grid_mobile {
    display: none;
  }
} */

/* .swal2-popup {
  font-size: 1.6rem !important;
} */
