.main_header {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 27px;
  color: black;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

.lebal_text {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 17px;
  color: black;
}
.back_arrow {
  font-size: 30;
  color: grey;
  display: inline-block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text_input_contact{
  height: 45px !important;
  color: #5a5a5a !important;
  border-radius: 2px !important;
  border: 1px solid rgb(212 209 209) !important;
}
