.modal-90w {
    max-width: 750px !important;
    position: relative;
    border: none;
    outline: none;
    box-shadow: 0 2px 7px rgba(57, 73, 76, 0.45);
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
}

.grid-example {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px !important;
    overflow-y: auto;
    max-height: 400px;
}

.logo {
    float: left;
    width: 100%;
    /* height: 80px; */
    /* padding: 0 20px 10px 0; */
}

.modal-headerModel {
    border: none;
    background: #f1f1f1;
    border-bottom: solid 1px #E1E5EC;
    padding: 20px 30px;
}

.headername {
    margin: 0;
    font-weight: 600;
    line-height: 1em !important;
    color: #606975;
    font-size: 1.75rem;
    font-family: "Calibri", sans-serif;
    letter-spacing: .25px;
}

.modal-90w .btn-close {
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px !important;
    height: 25px !important;
    margin: 0;
    z-index: 100000;
    background-color: #ffffff;
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.modal-90w .modal-dialog-centered {
    display: flex;
    align-items: center;
    /* min-height: calc(100% - var(--bs-modal-margin)* 2); */
}
/* 
.modal{
   overflow-y: hidden;
} */


.modal-90w .modal-content {
    border-radius: 7px !important;
}

.modal-90w .modal-footer {
    border-top: 0px;
}


.CloseButtton {
    font-family: "Calibri", sans-serif;
    letter-spacing: .25px;
    display: inline-block;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    width: 100%;
}

.CloseButtton.focus,
.CloseButtton:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
}

.name_branch {
    font-size: 1.1rem;
    margin: 10px 0 10px 0;
    padding: 0 0 5px 0;
    font-family: "Calibri", sans-serif;
    letter-spacing: .25px;
    text-transform: none;
    text-align: start;
    color: #212529;
    font-weight: 600;
    margin-top: 20px;
}

.Address {
    font-size: 0.9rem;
    color: #999;
    text-transform: uppercase;
    margin: 0px 0px 7px 0;
    padding: 0px 0px 0px 0;
}

.underline {
    border-bottom: 1px solid #ddd;
}

.dialog2-aria-describedby {
    font-family: "Calibri", sans-serif;
    font-size: 0.9rem;
    box-sizing: inherit;
    color: #606975;
    margin-top: 8px;
    margin-bottom: 1rem;
    color: #212529;
    font-weight: 500;
}

.phonenumber {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-family: "Calibri", sans-serif;
    font-size: 0.9rem;
    margin-top: 8px;
    margin-bottom: 1rem;
}

.time-container {
    display: inline-block;
    /* or display: block if you need to span the entire width */
    max-width: 100%;
    /* adjust based on your design requirements */
    overflow-x: auto;
    white-space: nowrap;
}

.trading-hours {
    font-family: "Calibri", sans-serif;
    list-style-type: none;
    margin: 10px 0 10px 0;
    padding: 0;
    font-size: 0.9rem;
}

.no-scroll {
    overflow-y: hidden;
  }

@media (max-width: 768px) {
    .trading-hours {
        margin: 5px 0 18px 0;
    }
}

.trading-hours li {
    font-family: "Calibri", sans-serif;
    margin: 0;
    padding: 0;
}

.trading-hours li b {
    font-family: "Calibri", sans-serif;
    display: inline-block;
    width: 100px;
}