.main_header {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  text-align: left;
  font-size: 27px;
  color: black;
}

.back_arrow {
  font-size: 30;
  color: grey;
  display: inline-block;
}

.order_type {
  font-family: "Calibri", sans-serif;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
}

.chf_text {
  font-family: "Calibri", sans-serif;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
}

.id_order {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
  /* margin-left: 34px; */
}

.date_and_time {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
  /* margin-left: 34px; */
}

.no_data_found {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 25px;
  margin-top: 46px;
}
