.MenuName{
      font-family: "Calibri", sans-serif !important;
      letter-spacing: 0.3px;
      font-size: 18px !important;
      color: #212529 ;
      font-weight: 500;
}


  .dropdown-menu {
      background-color: #ffffff;
      font-family: "Calibri", sans-serif !important; /* Background color */
      border: 1px solid #ced4da; /* Border */
      border-radius: 0.25rem; /* Border radius */
      box-shadow: none !important; 
    }
    
    .dropdown-item {
      color: #212529; 
      font-family: "Calibri", sans-serif;
      font-size: 16px;
      line-height: 40px;
      max-width: 100% !important;
      overflow: hidden; /* Prevents content from overflowing its container */
      text-overflow: ellipsis; /* Truncates text with an ellipsis (...) when it overflows */
      white-space: nowrap; 
    }
    
 .dropdown-item:hover,
 .dropdown-item:focus {
      background-color: #f8f9fa; 
      font-family: "Calibri", sans-serif !important;
      /* color: #E1A523; */
    }
    
      .dropdown-divider {
      border-color: #ced4da;
      font-family: "Calibri", sans-serif !important; /* Divider color */
    }


    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.8em !important;
      margin-top: 1px !important;
      vertical-align: 0.255em;
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      font-size: 19px !important;
  }


  .dropdown-menu[data-bs-popper] {
    top: 135% !important;
    left: -37px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

@media (max-width: 767px) {
  .dropdown-menu[data-bs-popper] {
    top: 132% !important;
    left: -30px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
  }
}


