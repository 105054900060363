
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');



.time_resservation {
      /* padding: 5px; */
      border: 1px solid rgb(208, 213, 221);
      border-radius: 15px;
      margin: 10px 0px;
      height: 54px;
      justify-content: center;
}

.tableRes_label {
      font-family: "Calibri", sans-serif !important;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 16px;
}

.tableRes_title_left {
      font-family: 'Marcellus', serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      /* color: #344054; */
      font-size: 32px;
}

.tableRes_title_center {
      font-family: 'Marcellus', serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      font-size: 27px;
}

.tableRes_text_left {
      font-family: 'Amiri', serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #475467;
      font-size: 20px;
}



.method_title {
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #667085;
      font-size: 16px;
}

.method_time_title {
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #667085;
      font-size: 15px;
}

.method_icon {
      height: 25px;
      width: 40px;
      cursor: pointer;
      color: #667085;
}

.time_text {
      font-family: "Calibri", sans-serif !important;
      /* font-weight: 500; */
      text-transform: none;
      letter-spacing: 0.25px;
      /* color: #344054; */
      font-size: 15px;
}

.card_des {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
      margin-bottom: 17px;
}

.text_text {
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #667085;
      font-size: 16px;
      text-align: center;
}


.scrolling_res {
      max-height: 230px;
      overflow-y: auto;
}

#dynamicSvg {
      position: absolute;
      top: 100 !important;
      left: 0;
      z-index: 999;
}

@media only screen and (max-width: 768px) {
      .time_text {
            font-size: 14px;
      }
      .tableRes_title_center{
            font-size: 23px;
      }

}


.Book_Table {
      /* background: #f25042 !important;
      color: #fff !important; */
      font-family: "Calibri", sans-serif !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      letter-spacing: .03em !important;
      text-transform: none !important;
      width: 100%;
      height: 45px !important;
      border-radius: 8px !important;
  }

  .tebalRes_label {
      font-family: "Calibri", sans-serif !important;
      font-weight: 500 ;
      text-transform: none ;
      letter-spacing: 0.25px ;
      color: #344054 ;
      font-size: 16px;
  }

  .table-checkbox {
      position: relative;
      border: 2px solid #D0D5DD;
      border-radius: 6px;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 11px !important;
      vertical-align: text-top;
      height: 25px;
      width: 25px;
      -webkit-appearance: none;
      opacity: .5;
  }
  

/* 
.custom-date-picker .MuiOutlinedInput-root {
      border-radius: 8px !important;
      height: 56px !important;
      font-family: "Calibri", sans-serif !important;
      transition: border-color 0.3s ease-in-out !important;
    }
    
    .custom-date-picker .MuiOutlinedInput-root:hover {
      border-color: #9d9ea0; 
    }
    
    .custom-date-picker .MuiOutlinedInput-root.Mui-focused {
      border-color: #076afd !important; 
      box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25) !important; 
    }
    
    .custom-date-picker .MuiInputLabel-root {
      font-size: 16px;
      color: #9d9ea0 !important;
      font-family: "Calibri", sans-serif !important;
      transition: color 0.3s ease-in-out !important;
    }
    
    .custom-date-picker .MuiInputLabel-root.Mui-focused {
      color: #076afd; 
    }
     */