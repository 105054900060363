.bHlsoG_Center {
      font-family: 'Calibri', sans-serif;
      font-weight: 600;
      letter-spacing: normal;
      line-height: 1.3336;
      margin: 0px 0.5rem 0px 0px;
      font-size: 15px;
    }
    
    @media only screen and (max-width: 768px) {
      .bHlsoG_Center {
        font-size: 17px; /* Adjust font size for mobile */
      }
    }
    