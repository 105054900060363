.proDuctPayment {
  overflow-y: auto !important;
  max-height: 492px !important;
}


.image_container {
  position: relative;
  display: inline-block;
  /* Ensures the container fits the image */
}


.product_img_payment {
  width: 77px;
  height: 66px;
  border-radius: 4px;
}


.download_rec_button {
  background-color: transparent !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
  color: #344054 !important;
  text-transform: none !important;
  font-size: 16px !important;
  height: 45px !important;
  width: 100% !important;
  font-family: "Calibri", sans-serif !important;
}

.back_to_home {
  /* border: 1px solid #D0D5DD !important; */
  border-radius: 8px !important;
  text-transform: none !important;
  font-size: 16px !important;
  height: 45px !important;
  width: 100% !important;
  font-family: "Calibri", sans-serif !important;
}

.dettt {
  display: flex;
  align-items: center;
  /* margin-top: -74px; */
}



@media only screen and (max-width: 768px) {
  .proDuctPayment {
    overflow-y: auto !important;
    max-height: 357px !important;
  }


  .download_rec_button {
    /* display: none !important; */
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .back_to_home {
    /* display: none !important;   */
    width: 100% !important;
  }


  .dettt {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
}



.header_success {
  font-family: "Calibri", sans-serif;
  font-weight: 600;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 23px;
}

.product_name_cart_ {
  font-family: "Calibri", sans-serif;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 17px;
  /* margin-left: -28px; */

}

.dis_payment {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.dis_payment1 {
  display: flex;
  align-items: center;
  justify-content: end;
}

.cart_product_chf {
  font-family: "Calibri", sans-serif;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 15px;
}

.branch_name_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: black;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 25px;
}

.branch_add_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: #30323BBA;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 16px;
}

.icon_tic {
  font-size: 76px;
  color: #039855;
}

.name_customer {
  font-family: "Calibri", sans-serif;
  font-weight: 700;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 24px;
  /* margin-left: -23px; */
}

.title_hed {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 20px;
}

.cus_deteails {
  font-family: "Calibri", sans-serif;
  font-weight: 400;
  color: #344054;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 16px;
}

.pay_card {
  border-radius: 15px;
  border: 0px;
}






.order_num_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  color: #000000;
  text-transform: none;
  letter-spacing: 0.25px;
  font-size: 20px;
  /* margin-left: -23px; */
}

.cart_product_chf_pay {
  font-family: "Calibri", sans-serif;
  font-weight: 600 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
}

/* Default styles (Desktop) */


@media only screen and (max-width: 768px) {
  .branch_name_pay {
    font-size: 20px;
  }


  .product_name_cart_ {
    font-size: 15px;
    margin-left: -15px;
  }

  .cart_product_chf_pay {
    font-size: 14px;
  }

  .branch_add_pay {
    font-size: 12px;
  }

  .icon_tic {
    font-size: 50px;
  }

  .name_customer {
    font-size: 17px;
    /* margin-left: -15px; */
  }

  .title_hed {
    font-size: 15px;
  }

  .cus_deteails {
    font-size: 12px;
  }

  .pay_card {
    padding: 8px;
    border-radius: 10px;
  }

  .order_num_pay {
    font-size: 16px;
    /* margin-left: -15px; */
  }


}

.discont__pay {
  font-family: "Calibri", sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0.25px !important;
  color: green !important;
  font-size: 16px !important;
}

.discont__amount {
  font-family: "Calibri", sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0.25px !important;
  color: green !important;
  font-size: 18px !important;
}





@media only screen and (max-width: 768px) {
  .header_success {
    font-size: 21px;
  }
}

.payment_text {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 19px;
  color: black;
}

.payment_order_id {
  font-family: "Calibri", sans-serif;
  font-weight: 550 !important;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 16px;
  color: black;
}

.payment_text_info {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  font-size: 14px;
  color: black;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}


/* Base image size */
.product_img_payment {
  width: 77px;
  height: 66px;
  border-radius: 4px;
}

/* Extra small (xs): <576px */
@media (max-width: 575px) {
  .product_img_payment {
    width: 60px;
    height: 54px;
  }
}

/* Small (sm): ≥576px */
@media (min-width: 576px) and (max-width: 767px) {
  .product_img_payment {
    width: 65px;
    height: 60px;
  }
}

/* Medium (md): ≥768px */
@media (min-width: 768px) and (max-width: 991px) {
  .product_img_payment {
    width: 70px;
    height: 64px;
  }
}

/* Large (lg): ≥992px */
@media (min-width: 992px) and (max-width: 1199px) {
  .product_img_payment {
    width: 62px;
    height: 60px;
  }
}
