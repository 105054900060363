.title{
      color:  #101828;
      font-family: "Calibri", sans-serif;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0.25px;
      font-size: 20px; 
}

.delivery_label{
      color:  #101828;
      font-family: "Calibri", sans-serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      font-size: 16px;  
      color: #344054;  
}

.modal-method{
      max-width: 750px !important;
    position: relative;
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
}

.modal-content{
      border-radius: 10px
}

.icon_{
      height: 25px;
      width: 23px;
      color: #475467;
}

.input_model{
      border: 1px solid #D0D5DD !important;
      border-radius: 8px !important;
      height: 45px !important;
      font-family: "Calibri", sans-serif;
}


.Cancle_button {
      font-family: "Calibri", sans-serif !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 24px;
      text-transform: none !important;
      width: 100%;
      height: 45px !important;
      border: 1px solid #D0D5DD !important;
      color: #344054 !important;
      border-radius: 8px !important;
  }


  .Aplly_button{
      /* background: #f25042 !important; */
      /* color: #fff !important; */
      font-family: "Calibri", sans-serif !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      letter-spacing: .03em !important;
      text-transform: none !important;
      width: 100%;
      height: 45px !important;
      border-radius: 8px !important;
  }

  @media only screen and (max-width: 768px) {
  /* .input_model{
      height: 40px !important;
      } */

      .scroling_{
            height: 500px !important;
            overflow-y: auto !important;
      }
  }