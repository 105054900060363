.title_{
      font-family: "Calibri", sans-serif !important;
      letter-spacing: 0.3px;
      font-size: 24px !important;
      color: #170F49 ;
      font-weight: 500;   
}

.Discripotion{
      font-family: "Calibri", sans-serif !important;
      letter-spacing: 0.3px;
      font-size: 19px !important;
      color: #6F6C90 ;
      font-weight: 300;     
}

._button_{
      font-family: "Calibri", sans-serif !important;
    letter-spacing: 0.3px;
    font-size: 18px !important;
    color: #170F49;
    font-weight: 600 !important;
    background: transparent ;
    width: 100%;
    border: 1px solid #EFF0F6;
    border-radius: 29px;
    height: 57px;
    box-shadow: 0px 5px 14px 0px rgba(74,58,255,0.09);
}

._button_:hover {
      color: #170F49;
    background: #fff !important;
    border: 1px solid #EFF0F6;
  }

  .Accept{
      font-family: "Calibri", sans-serif !important;
    letter-spacing: 0.3px;
    font-size: 18px !important;
    font-weight: 600;
    width: 100%;
    border: 0px solid #EFF0F6;
    border-radius: 29px;
    height: 57px;
    box-shadow: 0px 5px 14px 0px rgba(74,58,255,0.09);
}

.Accept:hover {
    border: 0px solid #EFF0F6;
  }

  .custom-modal{
      position: absolute;
      left: 25px; /* Adjust as needed */
      bottom: 10px; /* Adjust as needed */
      
  }

  .contentClassName{
    border-radius: 20px !important;
  }


  @media only screen and (max-width: 600px) {
      .custom-modal{
            left: 0px !important; /* Adjust as needed */
            top: 100px !important; /* Adjust as needed */   
        }

        .title_{
            font-size: 18px !important;
      }
      
      .Discripotion{
            font-size: 17px !important;
      }
    }