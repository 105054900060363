@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Kaushan+Script&family=Sriracha&display=swap');

.main_header {
  font-family: "Calibri", sans-serif;
  font-weight: 600;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 27px;
}

.buy_a_card {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 23px;
}

.gift_amount {
  font-family: "Calibri", sans-serif;
  font-weight: 590 !important;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 20px;
}

.please_sp {
  font-family: "Calibri", sans-serif;
  font-weight: 590 !important;
  color: rgba(48, 50, 59, 0.73);
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 15px;
}

.gift_card_hover:hover {
  transform: scale(1.07);
  -webkit-transform: scale(1.07);
}

.gift_card_hover {
  transition: transform 0.5s;
}

.gift_card_img {
  max-width: 16%;
}

@media (max-width: 760px) {
  .gift_card_img {
    max-width: 40%;
  }
  .main_header {
    font-family: "Calibri", sans-serif;
    font-weight: 600;
    color: black;
    text-transform: none;
    letter-spacing: 1.25px;
    font-size: 21px;
  }
}

.no_voucher {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  color: black;
  text-transform: none;
  letter-spacing: 0.45px;
  font-size: 21px;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}




    .gift_voucher{
      font-family: 'Sriracha', cursive;
      font-size: 23px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(140, 11, 4, 1);
      /* margin-top: -59px; */
    }

    

    .discount{
      height: 130px;
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
    }


    .gift_voucher_branch{
      font-family: 'Sriracha', cursive;
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: left;
      color:#667085;
      margin-top: -11px;;
    }

    

    .dear_name{
      color: #000;
      font-family: 'Sriracha', cursive;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      /* line-height: 40px;s */
    }

    .CHF_{
      color: #000;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      /* line-height: 40px; */
    }


    .your_message{
      color: #666;
      font-family: 'Sriracha', cursive;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.5px; 
      /* text-transform: capitalize; */
    }
    

    .code{
      color: #000;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.24px;
      text-transform: uppercase;
    }

    .validity{
      color: #666;
      text-align: right;
      font-family: 'Sriracha', cursive;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      /* margin-bottom: -12px; */
    }

    .span_{
      text-transform: capitalize;
    }

      



    @media (min-width: 768px) and (max-width: 1024px){
      .gift_voucher {
        font-size: 18px;
        font-weight: 400;
        margin-top: -4px;
      }
      .gift_voucher_branch{
        font-size: 16px;
        font-weight: 400;
        color:#667085;
        margin-top: -16px;;
      }
      .dear_name {
        font-size: 15px !important;
      }.CHF_{
        font-size: 16px !important;
      }.mobile_row{
        margin-top:  -12px;
      }.your_message{
        font-size: 14px;
      }.code{
        font-size: 14px;
      }.validity{
        font-size: 14px;
      }.gitf_card_title{
        font-size: 21px !important;
      }.common_line{
        font-size: 15px !important
      }.common_tittel{
        font-size: 17px !important;
        line-height: 35px !important;
      }.message_upto{
        font-size: 15px !important;
      }.email{
        font-size: 15px !important;
      }.validity{
        font-size: 11px !important;
      }.your_message{
        font-size: 12px !important;
      }
  }

    @media (max-width: 760px) {
      .gift_voucher {
        font-size: 18px;
        font-weight: 400;
        margin-top: -4px;
      }
      .gift_voucher_branch{
        font-size: 16px;
        font-weight: 400;
        color:#667085;
        margin-top: -20px;;
      }
      .dear_name {
        font-size: 15px !important;
      }.CHF_{
        font-size: 16px !important;
      }.mobile_row{
        margin-top:  -12px;
      }.your_message{
        font-size: 14px;
      }.code{
        font-size: 14px;
      }.validity{
        font-size: 14px;
      }.gitf_card_title{
        font-size: 21px !important;
      }.common_line{
        font-size: 15px !important
      }.common_tittel{
        font-size: 17px !important;
        line-height: 35px !important;
      }.message_upto{
        font-size: 15px !important;
      }.email{
        font-size: 15px !important;
      }.validity{
        font-size: 11px !important;
      }.your_message{
        font-size: 12px !important;
      }
    }

    .splide__pagination__page{
      height: 0px !important;
    }
    .Branch_selection{
      color: var(--gray-700, #344054);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; 
    }

    .common_line{
      color: var(--gray-500, #667085);
      font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
    }


    .gitf_card_title{
      color: var(--gray-900, #101828);
      font-family: "Calibri", sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; 
    }

    .buy_gift_card{
      font-family: "Calibri", sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; 
    }

    .common_tittel{
      font-family: "Calibri", sans-serif;
      font-size: 21px;
      font-style: normal;
      color: #09090a;
      line-height: 35px;
    }

    .common_line{
      font-family: "Calibri", sans-serif;
      font-size: 17px;
      font-style: normal;
    }

    .message_upto{
      font-family: "Calibri", sans-serif;
      font-size: 17px;
      font-style: normal;
      color: #6f7172;
    }

    .email{
      font-family: "Calibri", sans-serif;
      font-size: 18px;
      font-style: normal;
      color: #212222;
    }

    .splide__arrow{
      margin-left: -17px !important;
      z-index: 0 !important;
    }

    .splide__arrow--next{
      margin-right: -15px !important;
    }

    /* .splide__arrow{
      margin-right: 29px !important;
    }

      

    .splide__arrow splide__arrow--prev{
      margin-left: 29px !important;
    } */

    .card-container {
      position: relative;
    }
    
    .background-image {
      width: 100%;
      height: 200px;
    }
    
    .overlay-image {
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .overlay-image_mobile {
      position: absolute; 
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .background-image_mobile {
      width: 100%;
      height: 160px;
    }

    .background-image_table{
      width: 100%;
      height: 175px;
    }

    .positioner {
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.icon {
  position: absolute;
  right: -16px;
  bottom: -15px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 20px;
  /* border: 4px solid white;
  background: blue; */
}

.frame-4 {
  position: absolute;
  width: 450px;
  height: 16px;
  top: 42px;
  left: 0;
  background-color: var(--fireside);
  border-radius: 2px;
  transform: rotate(180deg);
  box-shadow: 0px 2px 0px #00000033;
}


.css-1c32n2y-MuiBadge-root {
  position: initial !important;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.css-1rzb3uu {
  position: initial !important;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}


.css-jcn4dz-MuiBadge-badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: bold !important;
  font-size: 0.75rem;
  min-width: 60px !important;
  line-height: 1;
  padding: 0 6px;
  height: 31px !important;
  border-radius: 15px !important;
  z-index: 1;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #FFFFFF !important;
  color: #8C0B04 !important;
  top: 0;
  right: 29px !important;
  font-size:  17px !important;
  -webkit-transform: scale(1) translate(50%, -50%);
  -moz-transform: scale(1) translate(50%, -50%);
  -ms-transform: scale(1) translate(50%, -50%);
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  border : 2px solid #8C0B04 !important
}


.css-zhwrlg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: bold !important;
  font-size: 0.75rem;
  min-width: 60px !important;
  line-height: 1;
  padding: 0 6px;
  height: 31px !important;
  border-radius: 15px !important;
  z-index: 1;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #FFFFFF !important;
  color: #8C0B04 !important;
  top: 0;
  right: 29px !important;
  font-size:  17px !important;
  -webkit-transform: scale(1) translate(50%, -50%);
  -moz-transform: scale(1) translate(50%, -50%);
  -ms-transform: scale(1) translate(50%, -50%);
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  border : 2px solid #8C0B04 !important
}

  