.goog-te-combo {
  height: 42px !important;
  width: 140px !important;
  border-radius: 5px !important;
  text-align: center !important;
  background: white !important;
  border: thin solid rgba(77, 77, 77, 0.3) !important;
  font-size: 9pt !important;
  font-weight: 600 !important;
  display: inline-block !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
  zoom: 1 !important;
  color: #4d4d4d !important;
  font-family: "Calibri", sans-serif !important;
  text-transform: none !important;
}


.kaushan-script-regular {
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-style: normal;
}


.goog-te-combo option {
  /* Add your styles here */
        font-size: 14px !important;
        margin-top: 5px !important;
        color: #4d4d4d !important;
        text-align: center !important;
        font-family: 'Calibri', sans-serif !important;
        text-transform: none !important;
        /* background-color: aqua !important; */
}


/* Style the options within the dropdown */
.goog-te-menu-value span {
  /* Your styles here */
  font-family: Arial, sans-serif;
}

/* Style the selected option */
.goog-te-menu-value span:first-child {
  /* Your styles here */
  font-weight: bold;
  color: #007bff; /* Change the color as needed */
}
.goog-te-combo:focus {
  outline: none; /* Remove default focus outline */
  border-color: #ff0000; /* Change the color to your desired color */
}

/* .goog-te-gadget {
  top: 0px !important;
  padding: 0px !important ;
  font-size: 0px !important;
} */


.goog-te-gadget .goog-te-combo {
  margin: 13px 10px 0px 60px !important;
  }
.skiptranslate span {
  display: none !important;
}

.goog-te-gadget-simple span {
  color :#fff !important ;

}


/* Change background color and font size of dropdown menu options */
.goog-te-menu-value > span,
.goog-te-menu-value:hover > span {
  background-color: rgb(223, 208, 209) !important; /* Change background color */
  font-size: 14px !important; /* Change font size */
}

/* Hide the text of the dropdown menu options */
.goog-te-menu-value span {
  display: none !important;
}

.skiptranslate {
  font-size: 0px !important;
}

/* .App {
  position: relative;
} */

#google_translate_element {
  position: absolute;
  z-index: 9999;
  top: 2px;
  right: 5px;
} 


/* #google_translate_element {
  position: fixed !important;
  z-index: 99999;
  bottom: 0px;
} */

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
  visibility: hidden;
}

#kt_body{
    top:0px !important;
}

.VIpgJd-ZVi9od-aZ2wEe{
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO{
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  color: #000 !important;
}
.VIpgJd-ZVi9od-xl07Ob-lTBxed {
  text-decoration: none !important;
  color: #00C !important ; 
  white-space: nowrap;
  margin-left: 4px !important;
  margin-right: 4px;
}

.goog-te-gadget-icon {
  display:none;
}
.goog-te-menu-value img {
    width: 20px;
    height: 15px;
    margin-right: 5px;
    vertical-align: middle;
  }


