.MuiListItem-gutters {
      padding: 0px 0px 0px 0px !important;
  }


  .SideTitle {
    font-family:"Calibri", sans-serif !important;
    font-size: 17px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-weight: bold;
}

.SideTitle__{
    font-family:"Calibri", sans-serif !important;
    font-size: 17px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-weight: 500;
}

.Side_sub_menu{
    font-family:"Calibri", sans-serif !important;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-weight: 400;  
}

/* 
  .css-10hburv-MuiTypography-root:focus-visible {
      color: red !important
  }
  
  .css-10hburv-MuiTypography-root:hover {
      color: black !important; 
  } */