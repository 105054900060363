.header_success {
  font-family: "Calibri", sans-serif;
  font-weight: 600;
  color: black;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 23px;
}

.payment_text {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 19px;
  color: black;
}

.payment_order_id {
  font-family: "Calibri", sans-serif;
  font-weight: 550 !important;
  text-transform: none;
  letter-spacing: 1.25px;
  font-size: 16px;
  color: black;
}

.payment_text_info {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  font-size: 14px;
  color: black;
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

/* @media (max-width: 500px) { 
  .Download_Receipt {
    transition: transform 0.5s;
  }
}  */
