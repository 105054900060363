.footer_text{
      font-family: "Calibri", sans-serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #212529;
      font-size: 16px;
  }

  .Section2H{
    color: #212529;
    font-family: "Calibri", sans-serif;
    font-weight: bold;
    text-transform: none;
    letter-spacing: 0.3px;
    font-size: 20px;
    cursor: pointer ;  
  }


  .Section_Div{
    color: #212529;
    font-family: "Calibri", sans-serif;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0.25px;
    font-size: 16px;
    text-decoration: none !important;
  }

  .Section_Div:hover {
    color: #E1A523; 
    
}


@media only screen and (max-width: 767px) {
    .mobile_ {
      margin-top: 50px;
    }
  }


  .scrollToTopButton {
    position: fixed !important;
    bottom: 65px !important;
    right: 30px !important;
    padding: 5px !important;
    cursor: pointer !important;
  }