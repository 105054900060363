.card_style {
      border: 0px;
      border-radius: 12px;
}

.product_img {
      width: 77px;
      height: 66px;
      border-radius: 6px;
}


.dis_p{
      display: flex;
      align-items: center;
      /* justify-content: center; */
    }
    
    .dis_p1{
      display: flex;
      align-items: center;
      justify-content: end;
    }

.product_name_cart {
      font-family: "Calibri", sans-serif;
      font-weight: 600 !important;
      text-transform: none;
      letter-spacing: 0.25px;
      color: black;
      font-size: 18px;
      margin-left: 10px;
}

.Product_item {
      display: flex;
      align-items: center;
      justify-content: center;
}


.ButtonGroup_Left {
      border: 1px solid #d0d5dd !important;
      color: #475467 !important;
      font-size: 15px !important;
      border-top-left-radius : 8px !important;
      border-bottom-left-radius : 8px !important
}


.ButtonGroup_center{
      /* max-width: 33px !important; */
      border: 1px solid #d0d5dd !important;
      color: #475467 !important;
      font-size: 15px !important;
}


.ButtonGroup_Right{
      border: 1px solid #d0d5dd !important;
      color: #475467 !important;
      font-size: 15px !important;
      border-top-right-radius : 8px !important;
      border-bottom-right-radius : 8px !important
}

.ButtonGroup_Qut {
      color: #000000 !important;
      font-size: 16px !important;
      font-family: "Calibri", sans-serif !important;
      font-weight: 400 !important;
      text-transform: none !important;
}

.options_Re {
      font-weight: 400;
      letter-spacing: 0.03px;
      color: #686665 !important;
      font-family: 'Calibri', sans-serif !important;
      line-height: 1.5 !important;
      font-size: 16px !important;
}

.discont__{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      color: green !important;
      font-size: 16px !important;
}

.delivery_fees{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      color: black !important;
      font-size: 16px !important;
}

.no_pro{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500 ;
      text-transform: none ;
      letter-spacing: 0.25px ;
      color: black ;
      font-size: 18px ;
      margin-bottom: 15px;    
}


.vat_charges{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      color: black !important;
      font-size: 16px !important;
}

.add_more_button {
      font-family: "Calibri", sans-serif !important;
      font-weight: 600 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
       border: 1px !important;
      /* color: #344054 !important; */
      /* border: 1px solid #D0D5DD !important; */
      /* background: #fff !important; */
      height: 43px !important;
      font-size: 16px !important;
      border-radius: 8px !important;
      width: 100% !important;
}

.hr_ {
      opacity: 0.1 !important;
}

.input_group {
      height: 52px !important;
}

.Order_note_icon {
      height: 30px !important;
      width: 30px !important;
}

.Order_note_ {
      background-color: #F9FAFB !important;
}

.Order_notes_input {
      color: rgb(90, 90, 90) !important;
      border-radius: 0px 6px 6px 0px !important;
      border: 1px solid rgb(212, 209, 209) !important;
      font-family: "Calibri", sans-serif !important;
      color: #667085 !important;
}

.order_method {
      font-family: "Calibri", sans-serif !important;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #000000;
      font-size: 20px;
}

.method_card {
      border: 1px solid #D0D5DD;
      border-radius: 8px;
      margin-bottom: 10px;
}

.fad_icon {
      box-shadow: none !important;
      /* background: #EAECF0 !important; */
      /* height: 50px !important;
            width: 50px !important; */
}

.Delivery_icon {
      height: 30px;
      width: 30px;
}

.circle_icon {
      height: 30px;
      width: 30px;
      cursor: pointer;
}

.method_name {
      font-family: "Calibri", sans-serif !important;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 18px;
      margin-left: 7px;
}

.cus_name{
      font-family: "Calibri", sans-serif !important;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 18px; 
}

.cus_email{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 16px;   
}

.cus_title{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 18px;   
}

.cus_edit_icon{
      height: 25px;
      width: 25px;
}

@media only screen and (max-width: 768px) {
      .cus_name, .cus_email, .cus_phone, .my-2 {
            display: block;
            margin-bottom: 0.5rem; /* Adds spacing for stacked elements */
      }
      .cus_icon {
            vertical-align: middle; /* Centers icons vertically */
      }

      .cus_name{
            font-weight: 600;
            font-size: 16px;   
      }

      .cus_email{
            font-weight: 400;
            font-size: 15px;   
      }

      .cus_edit_icon{
            height: 21px;
            width: 21px;
      }
}

.method_date{
      font-family: "Calibri", sans-serif !important;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 16px;    
}


.cus_icon{
      height: 25px;
      width: 25px;
  }

.coupon_button {
      font-family: "Calibri", sans-serif !important;
      font-weight: 600 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      border: 0px !important;
      /* background: #FEF3F2 !important; */
      /* color: #F25042 !important; */
      height: 44px !important;
      font-size: 16px !important;
      border-radius: 8px !important;
      width: 100%;
}

.billing_title {
      font-family: "Calibri", sans-serif !important;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #000000;
      font-size: 20px;
}

/* Apply styles only to checkboxes with the custom-checkbox class */
.custom-checkbox {
      position: relative;
      border: 2px solid #D0D5DD;
      border-radius: 6px;
      background: none;
      cursor: pointer;
      line-height: 0;
      margin: 0 .6em 0 0;
      outline: 0;
      padding: 11px !important;
      vertical-align: text-top;
      height: 25px;
      width: 25px;
      -webkit-appearance: none;
      opacity: .5;
    }
    
    .custom-checkbox:hover {
      opacity: 1;
    }
    
    .custom-checkbox:checked {
      background-color: #F25042;
      opacity: 1;
      border: 2px solid #F25042;
      
    }
    
    .custom-checkbox:before {
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      width: 8px;
      height: 15px;
      border: solid #FFF;
      border-width: 0 2px 2px 0;
      margin: -1px -1px 0 -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 2;
      
    }
    

.extra_charges_label{
      font-family: "Calibri", sans-serif !important;
      font-weight: 400 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      color: #344054 !important;
      font-size: 16px;
}


.extra_charges_price{
      font-family: "Calibri", sans-serif !important;
      font-weight: 400 !important;
      text-transform: none !important;
      letter-spacing: 0.25px !important;
      color: #000000 !important;
      font-size: 18px;
}

.order_summery_re {
      font-family: "Calibri", sans-serif;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0.25px;
      color: black;
      font-size: 20px;
}

.payment_method{
      font-family: "Calibri", sans-serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #000000;
      font-size: 20px; 
}

._label{
      font-family: "Calibri", sans-serif;
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #344054;
      font-size: 16px;    
}

.error_message{
      font-family: "Calibri", sans-serif;
      font-weight: 500;
      text-transform: none;
      letter-spacing: 0.25px;
      color: #3c434d;
      font-size: 16px;     
}


.cart_product_chf_dd {
      color: #2e2b2b !important;
      font-family: "Calibri", sans-serif !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      letter-spacing: .25px !important;
      text-transform: none !important;
  }

  .proDuct{
      overflow-y: auto !important;
      max-height: 513px !important;     
     }

@media only screen and (max-width: 768px) {
      .product_img {
            width: 45px;
            height: 41px;
            border-radius: 4px;
      }

      .product_name_cart {
            font-size: 15px;
      }

      .options_Re {
            font-size: 14px !important;
      }


      .ButtonGroup_Qut {
            font-size: 15px !important;
      }

      .ButtonGroup_Left {
            border: 1px solid #d0d5dd !important;
            color: #475467 !important;
            font-size: 13px !important;
            min-width: 30px !important;
            padding: 0px 7px !important;
      }

      .proDuct{
       overflow-y: auto !important;
       max-height: 373px !important;     
      }

      .ButtonGroup_Right {
            border: 1px solid #d0d5dd !important;
            color: #475467 !important;
            font-size: 13px !important;
            min-width: 30px !important;
            padding: 0px 7px !important;
      }

      .ButtonGroup_center {
            border: 1px solid #d0d5dd !important;
            color: #475467 !important;
            min-width: 30px !important;
            padding: 0px 7px !important;
      }


      .cart_product_chf_dd {
            color: #2e2b2b !important;
            font-size: 14px !important;
            font-weight: 600 !important;
        }

      .coupon_button{
            font-size: 14px !important;
      }

      .order_method{
            font-size: 17px;
      }

      .fad_icon{
            width: 42px !important;
            height: 42px !important;
      }

      .Delivery_icon{
            width: 25px !important;
            height: 25px !important;   
      }

      .method_name{
         font-size: 16px;   
      }

      .circle_icon{
            width: 25px ;
            height: 25px ;    
      }

      .billing_title{
            font-size: 18px;
      }
      .order_summery{
            font-size: 16px;
      }

      .order_summery_re{
            font-size: 18px;
      }

      .payment_method{
            font-size: 18px;
      }

     
}