@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Roboto:wght@300&display=swap");

.homepage:hover {
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
}

.modal-content {
  width: 83%;
  margin: 0 auto !important;
}

.noBranch {
  max-width: 11%;
}

@media (max-width: 760px) {
  .noBranch {
    max-width: 35%;
  }
}

.btn-close {
  margin-bottom: 7px !important;
  text-transform: lowercase;
}

.homepage {
  transition: transform 0.5s;
}

.css-19kzrtu {
  padding: 0px !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: black !important;
}

.css-1gsv261 {
  border-bottom: 0px !important;
}

.header_name {
  font-family: "Calibri", sans-serif;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 18px;
}

.apologies {
  font-family: "Calibri", sans-serif;
  font-weight: 500 !important;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 16px;
}

.branch_address {
  font-family: "Calibri", sans-serif;
  font-weight: 300;
  text-transform: none;
  letter-spacing: 0.25px;
  color: #333;
  font-size: 13px;
}

.sidebar_List {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 20px;
}

.order_food {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  color: black;
  font-size: 15px;
}

.proceed_button {
  font-family: "Calibri", sans-serif;
  font-weight: 500;
  border: 0;
  text-transform: none;
  height: 40px;
  letter-spacing: 1.7px;
  color: white;
  font-size: 16px;
  width: 187px;
  border-radius: 10px !important;
  background-color: #dc3545;
}

.css-1aquho2-MuiTabs-indicator {
  width: 94.891px !important;
}

.delivery_tab {
  color: black !important;
  font-family: "Calibri", sans-serif !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 19px !important;
  margin-left: -11px !important;
}

.pickup_tab {
  color: black !important;
  font-family: "Calibri", sans-serif !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 19px !important;
  margin-left: -9px !important;
}

.sidemenu:hover {
  transform: scale(1.03);
  -webkit-transform: scale(1.03);
}
.sidemenu {
  transition: transform 0.5s;
}

.hover14 figure {
  position: relative;
}
.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.hover14 figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
/* 
@media (max-width: 500px) {
  .homepage:hover {
    transform: scale(1.1);
  }
  .homepage {
    transition: transform 0.5s;
  }
} */

.heading {
  font-family: 'Calibri', sans-serif;
  text-align: center;
}

.action-btn {
  background-color: "#cb202d";
}

.custom_hover:hover {
  filter: brightness(80%) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container_gride_view_homePage {
  width: 100%;
  height: 230px;
  padding: 0 !important;
}

.container_gride_view_homePage .img-container_homePage {
  width: 100%;
  height: 230px !important;
}

.container_gride_view_homePage .img-container_homePage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px
}


.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(),var()) !important;
}