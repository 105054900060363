:root {
      --primary-font: 'Calibri', sans-serif;
      --primary-color: #181C32;
      --secondary-color: #5E6278;
      --neutral-color: rgb(69, 68, 68);
    }
    
    .order_number, .order_numbers {
      font-family: var(--primary-font);
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 0;
      color: var(--primary-color);
      text-transform: none;
      margin-top: 4px;
      margin-bottom: 15px;
    }
    
    .customer_info, .order_date {
      font-family: var(--primary-font);
      font-weight: 600;
      letter-spacing: 0;
      color: var(--primary-color);
      text-transform: none;
    }
    
    .customer_info {
      font-size: 21px;
    }
    
    .order_date {
      font-size: 16px;
      line-height: 28px;
      text-align: end;
    }
    
    .orderTitel, .orderValue {
      font-family: var(--primary-font);
      font-size: 14px;
      letter-spacing: 0;
      text-transform: none;
      color: var(--secondary-color);
    }
    
    .orderTitel {
      font-weight: 500;
    }
    
    .orderValue {
      font-weight: 600;
    }
    
    .details {
      display: flex;
    }
    
    .ema {
      margin-left: 13px;
    }
    
    .product-name, .product-price {
      font-family: var(--primary-font);
      font-size: 15px;
      font-weight: 600;
      color: var(--neutral-color);
      cursor: pointer;
      letter-spacing: 1px;
      margin-top: 8px;
    }
    
    .tableTitleHeade {
      background: rgb(242, 244, 247);
      border: 1px solid rgb(234, 236, 240);
      font-family: var(--primary-font);
      font-size: 14px;
      font-weight: 500;
      color: rgb(77, 88, 107);
    }
    
    .OrderNotes {
      font-family: var(--primary-font);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      color: var(--primary-color);
      text-transform: none;
      text-align: start;
    }
    
    @media only screen and (max-width: 767px) {
      .details {
        display: initial;
      }
    
      .ema {
        margin-left: 0;
      }
    
      .customer_info {
        font-size: 16px;
        text-align: start;
      }
    
      .order_date {
        font-size: 14px;
        text-align: start;
      }
    
      .product-name {
        font-size: 13px;
      }
    }
    